























































































































































import DeleteDialog from '@/components/admin/DeleteDialog.vue';
import EditReferenceDialog from '@/components/admin/EditReferenceDialog.vue';
import ReferenceDTO from '@/models/ReferenceDTO';
import FileService from '@/services/FileService';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        EditReferenceDialog,
        DeleteDialog,
    },
})
export default class References extends Vue {
    files: File[] = [];
    uploadProgress = 0;
    headers = [
        { text: 'Cenoteando ID', value: 'id' },
        { text: 'Authors', value: 'authors' },
        { text: 'Short Name', value: 'shortName' },
        { text: 'Type', value: 'type' },
        { text: 'Year', value: 'year' },
        { text: 'Actions', value: 'action' },
    ];
    types = [
        'BOOK',
        'BOOK_CHAPTER',
        'JOURNAL',
        'OTHER',
        'REPORT',
        'THESIS',
        'WEBPAGE',
    ];
    hasFile = [true, false];
    item = [];
    search = '';
    newReference = new ReferenceDTO();
    references: ReferenceDTO[] = [];
    filterType: string[] = [];
    filterHasFile: boolean[] = [];

    get filteredReferences(): ReferenceDTO[] {
        return this.references
            .filter(
                (r) =>
                    !this.filterType.length || this.filterType.includes(r.type),
            )
            .filter(
                (r) =>
                    !this.filterHasFile.length ||
                    this.filterHasFile.includes(r.hasFile),
            );
    }

    async created(): Promise<void> {
        await this.$store.dispatch('loading');

        (async () => {
            let generator = RemoteServices.referencesGenerator(30);
            for await (let batch of generator) {
                if (!this.references.length)
                    await this.$store.dispatch('clearLoading');

                this.references.push(...batch);
            }
        })().catch(async (error) => {
            await this.$store.dispatch('error', error);
        });
    }

    async createReference(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.createReference(this.newReference);
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
        this.newReference = new ReferenceDTO();
    }

    async updateReference(reference: ReferenceDTO): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.updateReference(reference);
        } catch (error) {
            // TODO: revert to original value in case of failure
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    async deleteReference(reference: ReferenceDTO): Promise<void> {
        await RemoteServices.deleteReference(reference.id);
        this.references = this.references.filter((r) => r.id != reference.id);
    }

    async download(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            const csv = await RemoteServices.referencesToCsv();
            FileService.download(csv, 'references.csv', 'text/csv');
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    selectFiles(files: File[]): void {
        this.uploadProgress = 0;
        this.files = files;
    }

    async upload(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.csvToReferences(this.files, (event) => {
                this.uploadProgress = Math.round(
                    (100 * event.loaded) / event.total,
                );
            });
        } catch (error) {
            this.uploadProgress = 0;
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }
}
