































































import ReferenceDTO from '@/models/ReferenceDTO';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        reference: ReferenceDTO,
    },
})
export default class EditReferenceDialog extends Vue {
    dialog = false;
    valid = false;

    types = [
        'BOOK',
        'BOOK_CHAPTER',
        'JOURNAL',
        'OTHER',
        'REPORT',
        'THESIS',
        'WEBPAGE',
    ];

    save(): void {
        this.$emit('onSave');
        this.dialog = false;
    }
}
